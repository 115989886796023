import Vue from 'vue'

/**
 * 移动数组的上下位置
 * @param n >= 1上移动 n <= -1下移动
 * @param index 当前索引
 */
export function moveSubArr(index, n) {
  let step = n >= 1 ? 1 : n <= -1 ? -1 : 0
  if (step === 0 || (!index && index !== 0)) {
    return false
  }
  // 判断如果是第一位元素或者是最后一位元素时候 不能上移动或者下移动
  if ((step === 1 && index === 0) || (step === -1 && index === this.length - 1)) {
    return false
  }
  let tempOption = this[index - step]
  Vue.set(this, index - step, this[index])
  Vue.set(this, index, tempOption)
}

/**
 * 判断 hash数组是否为空
 * @param arr
 * true 为空 false为非空
 */
export function emptyArray(arr) {
  if (!(arr instanceof Object)) {
    throw new Error('非法参数')
  }
  for (let key in arr) {
    if (arr[key]) {
      return false
    }
  }
  return true
}

/**
 * 重新获取修改内容
 * @param treeData
 */
export function refreshTree(fun, {data, tree}) {
  let node = data.node || data
  // 如果不是父节点 将所有子节点删除
  let pId = node.data.id
  if (node.level !== 0) {
    node.childNodes = []
  }
  // 再次请求资源 挂载至当前父节点下
  fun(pId).then(res => {
    res = res.data.content
    res.forEach((v, k) => {
      tree.append(v, node)
    })
  })
}

/**
 * 设置类数组对象中某个属性的值
 * @param arr
 * @param attr
 * @param key
 * @param val
 */
export function setObjArr(arr, attr, prop, key, val) {
  arr.forEach((v) => {
    if (v[attr] === prop) {
      v[key] = val
    }
  })
  return arr
}
/**
 * 数组对象去重
 * @param tempArr  要去重的数组
 */
export function unique(tempArr) {
  let newArr = [];
  for (let i = 0; i < tempArr.length; i++) {
    if (newArr.indexOf(tempArr[i].id) == -1) {
      newArr.push(tempArr[i].id);
    } else {
      tempArr.splice(i, 1);
    }
  }
  return tempArr;
}
