<template>
  <div
    class="quickoper"
    style="position: fixed; right: 10px; bottom: 10px; z-index: 1000"
  >
    <div>
      <div style="background: #ffffff">
        <div class="quicktop">
          <span style="margin-left: 10px; padding: 10px; background: #ffffff">
            <!-- 快速操作错误 -->
            {{ $t("label.knowledgebase.fast.operation.error") }}
          </span>
          <div>
            <span
              class="el-icon-minus"
              style="margin-right: 10px; margin-top: 10px"
              @click="narrow"
            ></span>
            <span
              class="el-icon-close"
              @click="close"
              style="margin-right: 10px"
            ></span>
          </div>
        </div>
        <el-button
          style="margin-left: 380px"
          v-if="hide"
          @click="Closesolution"
        >
          <!-- 关闭并解决 -->
          {{ $t("label.knowledgebase.close.and.resolve") }}
        </el-button>
      </div>

      <el-table
        v-if="hide"
        ref="multipleTable"
        :data="errortable"
        tooltip-effect="dark"
        height="400"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!-- 名称 -->
        <el-table-column
          prop="articleName"
          :label="$t('label.emailtocloudcc.name')"
        >
        </el-table-column>
        <!-- 错误 -->
        <el-table-column prop="errorDescription" :label="$t('error')">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import * as knowledgearticlesApi from "./api.js";

export default {
  props: {
    state: {
      type: Boolean,
    },
    errortable: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      multipleSelection: [],
      narrowstate: false,
      hide: true,
      selectallid: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.selectallid = [];
      this.multipleSelection = val;
      for (let i in this.multipleSelection) {
        this.selectallid.push(this.multipleSelection[i].id);
      }
    },
    narrow() {
      this.hide = !this.hide;
    },
    close() {
      this.$emit("close");
    },
    Closesolution() {
      let params = {
        articleId: this.selectallid.join(","),
      };
      knowledgearticlesApi.operationErrorInfo(params).then((res) => {
        this.close();
        this.$emit("refreshright");
        //控制错误按钮的显示
        // if(res.data.count > 0){
        this.$emit("errorchange", res.data.count);
        // }
        // this.hide = false;
      });
    },
    open() {
      this.hide = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.quickoper {
  width: 500px;
  // height: 400px;
  border: 2px solid #e5e5e5;
  .quicktop {
    display: flex;
    justify-content: space-between;
  }
}
</style>