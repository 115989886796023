<template>
  <div id="table-panel">
    <!-- 表格组件 -->
    <div class="content-main" :class="{ 'open-new-line': newLine }">
      <!-- 骨架屏 -->
      <Wang-skeleton
        type="line"
        :options="skeletonOptions"
        v-if="pageNum === 1"
      >
      </Wang-skeleton>  
      <!-- 我最近查看的等特殊视图不展示编辑字段按钮 -->
      <div
        class="showEditTableField-box"
        v-if="
          pageNum !== 1 &&
          showIndex &&
          showTableHeadTool &&
          viewModify === 'true'
        "
      >
        <el-popover
          placement="bottom-start"
          width="510"
          :visible-arrow="false"
          trigger="click"
          v-model="showEditTableFieldPopover"
        >
          <p>
            <!-- 选择要显示的字段 -->
            {{ $t("label.view.selectrenderfields") }}
          </p>
          <div class="table-head-tool">
            <div class="pull-left dragBox">
              <oneShuttle
                ref="oneShuttle"
                :unselectedFieldList="unselectedFieldList"
                :selectedFieldList="selectedFieldList"
                @memberFun="memberFun"
              ></oneShuttle>
            </div>
            <div class="btns">
              <el-button
                size="small"
                style="margin-top: 10px"
                @click="hideTool()"
              >
                <!-- 取消 -->
                {{ $t("label.cancel") }}
              </el-button>
              <el-button
                type="primary"
                size="small"
                @click="saveSelectedField()"
              >
                <!-- 保存 -->
                {{ $t("label.save") }}
              </el-button>
            </div>
          </div>
          <!-- </div> -->
          <span slot="reference">
            <svg
              ref="iconTool"
              id="iconTool"
              class="icon admin_s"
              aria-hidden="true"
              @mouseover="searchViewDetail"
            >
              <use href="#icon-gl"></use>
            </svg>
          </span>
        </el-popover>
        <!-- <span v-else>序号</span> -->
      </div>

      <!-- showTable -->
      <el-table
        ref="tablePanel"
        :data="dataList"
        v-if="pageNum !== 1"
        :border="border"
        :height="tableHeight"
        :row-class-name="rowClassName"
        :span-method="spanMethod"
        aria-activedescendant=""
        v-loading="pictLoading"
        :header-row-class-name="headerRowClassName"
        cell-class-name="cellClassName"
        @header-dragend="headerDragend"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
        @select-all="selectAll"
        @selection-change="selectionChange"
        @select="select"
        @row-click="rowClick"
        @cell-dblclick="cellDoubleClick"
        @toggleSelection="clearSelection"
        :empty-text="$t('label.dashboard.noRecord')"
        :stripe="stripe"
        :style="{ width: '100%', 'min-height': minHeight ? '418px' : '0' }"
      >
        <el-table-column
          width="50"
          v-if="showIndex && showTableHeadTool && resulterror"
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.errInfo"
              class="item"
              effect="dark"
              :content="scope.row.errInfo"
              placement="top-start"
            >
              <svg
                class="icon lockedImg"
                aria-hidden="true"
                style="width: 18px; height: 18px"
              >
                <use xlink:href="#icon-warning_round"></use>
              </svg>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 最左侧操作列 -->
        <el-table-column
          v-if="showIndex && showTableHeadTool"
          align="center"
          type="index"
          fixed
          :resizable="false"
          :index="indexMethod"
          :show-overflow-tooltip="true"
          width="55"
        >
        </el-table-column>
        <!-- 序号 -->
        <el-table-column
          v-if="showIndex && !showTableHeadTool"
          :label="$t('label.order.number')"
          align="center"
          type="index"
          fixed
          :resizable="false"
          :index="indexMethod"
          width="61"
        >
        </el-table-column>

        <el-table-column
          v-if="checked"
          type="selection"
          fixed
          :resizable="false"
          width="51"
        >
        </el-table-column>
        <!-- 表头&内容展示区 -->
        <template v-for="(item, itemIndex) in tableAttr">
          <slot :name="item.schemefieldName" :item="item">
            <!-- html展示数据，一般用于富文本框 -->
            <el-table-column
              v-if="
                !item.click && !item.options && !item.isInput && item.ishtml
              "
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="true"
            >
              <!-- 自定义表头部分 -->
              <template slot="header" v-if="customHeader">
                <!-- 自定义表头信息 -->
                <div class="tableHeadField" @click="changeSortFiled(item)">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">
                      {{ $t("vue_label_commonobjects_detail_locking") }}
                    </el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <div v-html="scope.row[item.schemefieldName]"></div>
              </template>
            </el-table-column>
            <!-- 普通文本类型数据 -->
            <el-table-column
              v-if="
                !item.click && !item.options && !item.isInput && !item.ishtml
              "
              :align="item.align ? item.align : 'left'"
              :width="item.colwidth"
              :label="item.nameLabel"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="true && item.schemefieldType !== 'B'"
            >
              <template slot="header" v-if="customHeader">
                <div class="tableHeadField" @click="changeSortFiled(item)">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <span>
                  <!-- 日期 -->
                  <span class="cannotEdit" v-if="item.schemefieldType === 'D'">
                    {{
                      scope.row[item.schemefieldName] | dateFormat(countryCode)
                    }}
                  </span>
                  <!-- 日期时间 -->
                  <span
                    class="cannotEdit"
                    v-else-if="item.schemefieldType === 'F'"
                  >
                    {{
                      scope.row[item.schemefieldName]
                        | datetimeFormat(countryCode)
                    }}
                  </span>
                  <!-- 记录类型 -->
                  <span
                    class="cannotEdit"
                    v-else-if="item.schemefieldType === 'R'"
                  >
                    {{ scope.row[`${item.schemefieldName}ccname`] }}
                  </span>

                  <!-- 复选框 -->
                  <span
                    class="cannotEdit"
                    v-else-if="item.schemefieldType === 'B'"
                  >
                    <el-checkbox
                      :value="scope.row[item.schemefieldName] === 'true'"
                      disabled
                    >
                    </el-checkbox>
                    <!-- {{scope.row[item.schemefieldName]  === 'true'? '是': scope.row[item.schemefieldName]  === 'false'? '否' :''}} -->
                  </span>
                  <!-- URL -->
                  <span
                    class="cursor-pointer allow-click"
                    v-else-if="item.schemefieldType === 'U'"
                    @click="jumpToUrl(scope.row[item.schemefieldName])"
                  >
                    {{ scope.row[item.schemefieldName] }}
                  </span>
                  <!-- 标签 -->
                  <span
                    class="cannotEdit"
                    v-else-if="
                      item.schemefieldType === 'X' &&
                      item.schemefieldName === 'cloudcctag'
                    "
                  >
                    <el-tag
                      v-for="tag in scope.row['cloudcctagList']"
                      :key="tag.name"
                      :type="
                        tag.color === 'hui'
                          ? 'info'
                          : tag.color === 'hong'
                          ? 'danger'
                          : tag.color === 'lv'
                          ? 'success'
                          : tag.color === 'lan'
                          ? ''
                          : tag.color === 'huang'
                          ? 'warning'
                          : ''
                      "
                      effect="dark"
                      class="tag"
                    >
                      {{ tag.name }}
                    </el-tag>
                  </span>
                  <!-- 图片 -->
                  <span
                    v-else-if="
                      item.schemefieldType === 'IMG' &&
                      item.expressionType !== 'url'
                    "
                    class="cannotEdit"
                    style="width: 100%; display: inlne-block"
                  >
                    <img
                      v-if="
                        scope.row[item.schemefieldName] !== undefined &&
                        scope.row[item.schemefieldName] !== '' &&
                        scope.row[item.schemefieldName] !== null
                      "
                      :src="imgSrc(scope.row[item.schemefieldName])"
                      style="width: 100%"
                    />
                  </span>
                  <!-- 图片 引用类型 -->
                  <span
                    v-else-if="
                      item.schemefieldType === 'IMG' &&
                      item.expressionType === 'url'
                    "
                    class="cannotEdit"
                  >
                    <img
                      v-if="
                        scope.row[item.schemefieldName] !== undefined &&
                        scope.row[item.schemefieldName] !== '' &&
                        scope.row[item.schemefieldName] !== null
                      "
                      :src="scope.row[item.schemefieldName]"
                    />
                  </span>
                  <!-- 评分 -->
                  <span
                    v-else-if="item.schemefieldType === 'SCORE'"
                    class="cannotEdit stars"
                  >
                    <span v-show="false">{{
                      scope.row[item.schemefieldName] === null ||
                      scope.row[item.schemefieldName] === undefined
                        ? 0
                        : Number(scope.row[item.schemefieldName])
                    }}</span>
                    <span
                      v-for="(star, idx) in Number(item.schemefieldLength) + 1"
                      v-show="idx !== 0"
                      :class="{
                        show2: idx <= Number(scope.row[item.schemefieldName]),
                      }"
                      :key="idx"
                    >
                    </span>
                  </span>
                  <!-- 潜在客户打分 -->
                  <span
                    v-else-if="item.schemefieldName === 'leadscore'"
                    class="cannotEdit"
                  >
                    <span style="width: 25px; display: inline-block">{{
                      scope.row[item.schemefieldName]
                    }}</span>
                    <el-progress
                      :percentage="
                        scope.row[item.schemefieldName] !== undefined &&
                        scope.row[item.schemefieldName] !== null
                          ? Number(scope.row[item.schemefieldName])
                          : 0
                      "
                      :color="customColors"
                      :format="formatProgress"
                    >
                    </el-progress>
                  </span>
                  <!-- 文件 -->
                  <span
                    v-else-if="item.schemefieldType === 'FL'"
                    class="cannotEdit"
                  >
                    <span
                      v-for="file in scope.row[item.schemefieldName + 'List']"
                      :key="file.fileid"
                      class="fileItem"
                      @click="downLoadFile(file)"
                    >
                      {{ file.filename }}
                    </span>
                  </span>
                  <!-- 任务及事件下的名称对象及相关对象 -->
                  <span
                    v-else-if="
                      item.schemefieldName === 'relateobj' ||
                      item.schemefieldName === 'whoobj'
                    "
                    class="cannotEdit"
                  >
                    <span>
                      {{
                        scope.row[`${item.schemefieldName}`] === "null"
                          ? ""
                          : scope.row[`${item.schemefieldName}`]
                      }}
                    </span>
                  </span>
                  <!-- 相关列表字段跟踪、操作operate -->
                  <span
                    v-else-if="
                      item.schemefieldName === 'operate' ||
                      item.schemefieldName === 'clxx'
                    "
                    class="cannotEdit"
                    v-html="scope.row[item.schemefieldName]"
                  >
                  </span>
                  <!-- 普通文本 -->
                  <span class="cannotEdit" v-else>
                    {{ scope.row[item.schemefieldName] }}
                  </span>
                  <!--事件及任务下的名称及相关项去除编辑/相关列表文件、类型attachtype去除编辑-->
                  <svg
                    v-if="
                      item.schemefieldName !== 'relateobj' &&
                      item.schemefieldName !== 'whoobj' &&
                      item.schemefieldName !== 'attachtype'
                    "
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                  <!-- 电话字段回复标识 -->
                  <svg
                    class="icon"
                    v-if="
                      replyPhoneShow &&
                      item.schemefieldType === 'H' &&
                      scope.row[item.schemefieldName]
                    "
                    aria-hidden="true"
                    @click="callPhone(scope.row[item.schemefieldName])"
                    style="cursor: pointer"
                  >
                    <use href="#icon-Call"></use>
                  </svg>
                </span>
              </template>
            </el-table-column>
            <!--  单选下拉框 -->
            <el-table-column
              v-if="item.options && !item.isInput"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="item.tooltip"
            >
              <template slot="header" v-if="customHeader">
                <div class="tableHeadField" @click="changeSortFiled(item)">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <!-- 等待修改,是否需要添加tooltip -->
              <template slot-scope="scope">
                <span
                  v-if="
                    (item.apiname == 'severity' ||
                      item.apiname == 'priority') &&
                    (item.options[scope.row[item.schemefieldName]] == '高' ||
                      scope.row[item.schemefieldName] == '高')
                  "
                  style="color: red"
                  v-html="showSelectVal(item, scope)"
                >
                </span>
                <span v-else v-html="showSelectVal(item, scope)"> </span>
                <svg
                  class="icon editIconImg"
                  aria-hidden="true"
                  style="display: none"
                  @click="editCell(item, scope.row)"
                >
                  <use href="#icon-pen"></use>
                </svg>
              </template>
            </el-table-column>
            <!-- a标签可跳转类型字段 - 查找、查找多选、所有人 -->
            <el-table-column
              v-if="item.click && !item.isInput && useHyperlinks"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="columnTooltip"
            >
              <template slot="header" v-if="customHeader">
                <div class="tableHeadField" @click="changeSortFiled(item)">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <!-- 名称 主题-->
                <!-- 非文件相关列表需要跳转，反之下载 -->
                <a
                  class="cursor-pointer allow-click"
                  v-if="
                    (objId &&
                      objId !== 'attachement' &&
                      item.schemefieldName === 'name') ||
                    item.schemefieldName === 'subject'
                  "
                  :href="nameHrefUrl(scope.row)"
                  @click="jumpTo(item, scope, item.click)"
                >
                  <Popover-cpn
                    v-if="
                      caseKnowList === true &&
                      objId === 'cloudcc_k_article' &&
                      item.name === 'name'
                    "
                    :recordObj="scope.row"
                    :content="scope.row[`${item.schemefieldName}`]"
                    :knowOwnImg="true"
                    @jumpDetail="jumpDetail(scope.row)"
                  />
                  <div v-else>{{ scope.row[`${item.schemefieldName}`] }}</div>
                  <svg
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                </a>
                <span
                  class="cursor-pointer allow-click"
                  v-else-if="
                    objId &&
                    objId === 'attachement' &&
                    item.schemefieldName === 'name'
                  "
                  @click="downLoadFile(scope.row)"
                >
                  {{ scope.row[`${item.schemefieldName}`] }}
                </span>
                <!-- 所有人 - 非队列 : 可跳转 -->
                <a
                  class="cursor-pointer allow-click"
                  v-else-if="
                    item.schemefieldType === 'Y' &&
                    item.lookupObj === 'user' &&
                    scope.row[`${item.schemefieldName}`] &&
                    scope.row[`${item.schemefieldName}`].slice(0, 3) === '005'
                  "
                  :href="hrefUrl(scope.row, item)"
                  @click="jumpTo(item, scope, item.click)"
                >
                  {{ scope.row[`${item.schemefieldName}ccname`] }}
                  <svg
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                </a>
                <!-- 所有人 - 队列 : 不可跳转 -->
                <span
                  v-else-if="
                    item.schemefieldType === 'Y' &&
                    item.lookupObj === 'user' &&
                    scope.row[`${item.schemefieldName}`] &&
                    scope.row[`${item.schemefieldName}`].slice(0, 3) !== '005'
                  "
                >
                  {{ scope.row[`${item.schemefieldName}ccname`] }}
                  <svg
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                </span>

                <!-- 查找字段 公海池字段不支持跳转 -->
                <a
                  class="cursor-pointer allow-click"
                  v-else-if="
                    item.schemefieldType !== 'MR' &&
                    item.apiname !== 'marketsea'
                  "
                  :href="hrefUrl(scope.row, item)"
                  @click="jumpTo(item, scope, item.click)"
                >
                  <!-- 名称和相关项 -->
                  <span
                    v-if="
                      item.schemefieldName === 'relateid' ||
                      item.schemefieldName === 'whoid'
                    "
                  >
                    {{
                      item.schemefieldName === "relateid" ||
                      item.schemefieldName === "whoid"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                  </span>
                  <span v-else>
                    {{
                      item.schemefieldType === "Y" ||
                      item.schemefieldType === "MR" ||
                      item.schemefieldType === "M"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                    <svg
                      class="icon editIconImg"
                      aria-hidden="true"
                      style="display: none"
                      @click="editCell(item, scope.row)"
                    >
                      <use href="#icon-pen"></use>
                    </svg>
                  </span>
                </a>
                <span v-else>
                  {{
                    item.schemefieldType === "Y" ||
                    item.schemefieldType === "MR" ||
                    item.schemefieldType === "M" ||
                    item.schemefieldName === "relateid" ||
                    item.schemefieldName === "whoid"
                      ? scope.row[`${item.schemefieldName}ccname`]
                      : scope.row[`${item.schemefieldName}`]
                  }}
                  <svg
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                </span>
              </template>
            </el-table-column>
            <!-- 不使用a标签 - 查找、查找多选、所有人 -->
            <el-table-column
              v-if="item.click && !item.isInput && !useHyperlinks"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="columnTooltip"
            >
              <template slot="header" v-if="customHeader">
                <div class="tableHeadField" @click="changeSortFiled(item)">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <!-- 查找字段 -->
                <span
                  class="cursor-pointer allow-click"
                  v-if="item.schemefieldType !== 'MR'"
                  @click="jumpTo(item, scope, item.click)"
                >
                  <!-- 名称和相关项 -->
                  <span
                    v-if="
                      item.schemefieldName === 'relateid' ||
                      item.schemefieldName === 'whoid'
                    "
                  >
                    {{
                      item.schemefieldName === "relateid" ||
                      item.schemefieldName === "whoid"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                  </span>
                  <span v-else>
                    {{
                      item.schemefieldType === "Y" ||
                      item.schemefieldType === "MR" ||
                      item.schemefieldType === "M"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                    <svg
                      class="icon editIconImg"
                      aria-hidden="true"
                      style="display: none"
                      @click="editCell(item, scope.row)"
                    >
                      <use href="#icon-pen"></use>
                    </svg>
                  </span>
                </span>
                <span v-else>
                  {{
                    item.schemefieldType === "Y" ||
                    item.schemefieldType === "MR" ||
                    item.schemefieldType === "M" ||
                    item.schemefieldName === "relateid" ||
                    item.schemefieldName === "whoid"
                      ? scope.row[`${item.schemefieldName}ccname`]
                      : scope.row[`${item.schemefieldName}`]
                  }}
                  <svg
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                </span>
              </template>
            </el-table-column>
            <!-- 数值类型 -->
            <el-table-column
              v-if="item.isInput"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="columnTooltip"
            >
              <template slot="header" v-if="customHeader">
                <div class="tableHeadField" @click="changeSortFiled(item)">
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <!-- <span style="label">
                    {{ nameLableList[itemIndex] }}
                  </span> -->
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row[item.schemefieldName]"
                  v-if="item.inputAttr.type === 'number'"
                  size="mini"
                  :min="item.inputAttr ? item.inputAttr.min : 0"
                  :max="item.inputAttr ? item.inputAttr.max : 100"
                >
                </el-input-number>

                <!-- 循环input类型为 radio的取值 -->
                <el-radio-group
                  v-if="item.inputAttr.type === 'radio'"
                  v-model="scope.row[item.schemefieldName]"
                >
                  <template v-for="radioItem in item.inputAttr.options">
                    <el-radio :label="radioItem.val">
                      {{ radioItem.label }}
                    </el-radio>
                  </template>
                </el-radio-group>

                <el-input
                  v-if="item.inputAttr.type === 'text'"
                  v-model="scope.row[item.schemefieldName]"
                  size="mini"
                  :min="item.inputAttr.minLength || 0"
                  :max="item.inputAttr || 100"
                >
                </el-input>
                <svg
                  class="icon editIconImg"
                  aria-hidden="true"
                  style="display: none"
                  @click="editCell(item, scope.row)"
                >
                  <use href="#icon-pen"></use>
                </svg>
              </template>
            </el-table-column>
          </slot>
        </template>

        <!-- 操作列 -->
        <el-table-column
          v-if="tableButton"
          :label="''"
          :align="tableButton.align || 'center'"
          style="text-align: center"
          :width="tableButton.width || '80'"
        >
          <template slot-scope="scope">
            <!-- 是否是个案下的文章列表 -->
            <div v-if="caseKnowList === true">
              <!-- 草稿 -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publish_status === $t('label.knowledgebase.draft')
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>

                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.buttonListKnowone"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
              <!-- '已发布' -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publish_status ===
                    $t('label.knowledgebase.published') &&
                  !scope.row.conditional
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.buttonListKnowtwo"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
              <!-- '已发布' -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publish_status ===
                    $t('label.knowledgebase.published') && scope.row.conditional
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.buttonListKnowfour"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
              <!-- '已归档' -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="scope.row.publish_status === $t('label.archived')"
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.buttonListKnowthree"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
            </div>
            <div v-else>
              <!-- 主版本 -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publishStatus == $t('label.archived') &&
                  scope.row.is_master_language == 'true' &&
                  !scope.row.conditional
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.buttonListone"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
              <!-- 主版本有草稿 -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publishStatus == $t('label.archived') &&
                  scope.row.is_master_language == 'true' &&
                  scope.row.conditional == 'true'
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.buttonListonedraft"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
              <!-- 草稿 -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publishStatus == $t('label.knowledgebase.draft') &&
                  scope.row.is_master_language == 'true'
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.buttonListtwo"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
              <!-- '已发布' -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publishStatus ==
                    $t('label.knowledgebase.published') &&
                  scope.row.is_master_language == 'true' &&
                  !scope.row.conditional
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.buttonList"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
              <!-- '已发布' -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publishStatus ==
                    $t('label.knowledgebase.published') &&
                  scope.row.is_master_language == 'true' &&
                  scope.row.conditional == 'true'
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.buttonListdraft"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
              <!-- 翻译版本 -->
              <!-- 暂时更改 -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publishStatus == $t('label.archived') &&
                  (scope.row.is_master_language == 'false' ||
                    !scope.row.is_master_language)
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <span
                  v-for="(btnItem, index) in tableButton.transButtonListtwo"
                  class="disableGray"
                  :key="index"
                >
                  {{ btnItem.label }}
                </span>
              </el-popover>
              <!-- 草稿 -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publishStatus == $t('label.knowledgebase.draft') &&
                  (scope.row.is_master_language == 'false' ||
                    !scope.row.is_master_language)
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.transButtonList"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
              <!-- '已发布' -->
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
                v-show="
                  scope.row.publishStatus ==
                    $t('label.knowledgebase.published') &&
                  (scope.row.is_master_language == 'false' ||
                    !scope.row.is_master_language)
                "
              >
                <svg
                  slot="reference"
                  class="icon tableButton"
                  aria-hidden="true"
                >
                  <use xlink:href="#icon-xiala"></use>
                </svg>
                <ul>
                  <li
                    v-for="(btnItem, index) in tableButton.transButtonListone"
                    :key="btnItem.action"
                    @click="handleTableAction(scope, btnItem)"
                  >
                    <span
                      class="cursor-pointer"
                      :disabled="btnItem.disabled"
                      :key="index"
                    >
                      {{ btnItem.label }}
                    </span>
                  </li>
                </ul>
              </el-popover>
            </div>
          </template>
        </el-table-column>

        <slot></slot>
      </el-table>
      <!-- 表格内联编辑 -->
      <el-dialog
        :title="$t('label.change')"
        :visible.sync="dialogVisible"
        top="15%"
        width="30%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <p
          class="pull-left"
          style="height: 30px; line-height: 30px; margin-right: 10px"
        >
          {{ field }}
        </p>
        <editable-cell
          ref="editableCell"
          :field="field"
          :schemefieldLength="schemefieldLength"
          :options="editableCellOptions"
          :value="toChangeValue"
          :isFilter="false"
          :input-type="inputType"
          :err-tip="errTip"
          :precision="precision"
          :min="min"
          :max="max"
          origin-type="table"
          width="95%"
          @remoteSearch="remoteSearch"
        >
        </editable-cell>

        <p
          style="text-align: left; padding-top: 10px; clear: both"
          v-if="showApply2Others"
        >
          <!-- 是否应用于选中数据 -->
          <el-checkbox v-model="changeAll">{{
            $t("vue_label_commonobjects_view_change_all_selected_records")
          }}</el-checkbox>
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogVisible = false">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="saveChange"
            :loading="isSaveBtnLoading"
          >
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>

      <!-- 查找/查找多选 -->
      <el-dialog
        :title="$t('label.searchs')"
        width="60%"
        top="5%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        :before-close="beforeClose"
        append-to-body
      >
        <div
          :style="{
            height: dialogBodyHeight,
            overflow: 'auto',
            padding: '0 20px',
          }"
        >
          <search-table
            ref="searchTable"
            :fieldId="relevantFieldId"
            :checked="multiChecked"
            :relevant-objid="relevantObjId"
            :relevant-objapi="relevantObjApi"
            :relevant-prefix="relevantPrefix"
            :projectId="projectId"
            :isKnowledge="isKnowledge"
            @changeSelect="changeSelect"
            @setFieldReltaion="setFieldReltaion"
          />
        </div>
      </el-dialog>
    </div>
    <!-- 服务报告预览 -->
    <file-preview
      :show="showPreview"
      :showUrl="previewUrl"
      :showData="showPreviewData"
      @closePreview="closePreview"
      ref="previewFile"
    >
    </file-preview>
  </div>
</template>

<script type="text/ecmascript-6">
import { emptyArray } from "@/utils/array";
import EditableCell from "./EditableCell.vue";
import draggable from "vuedraggable";
import INPUTTYPE from "@/config/enumCode/inputType.js";
import * as CommonObjApi from "./api";
import oneShuttle from "../ViewBox/oneShuttle.vue";
import filePreview from "@/components/FileView/filePreview";
import Quickoperationerror from "@/components/Quickoperationerror/index";
import PopoverCpn from "@/views/homePage/components/PopoverCpn";
import * as callCenterApi from "./api.js"; // 呼叫中心接口
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  components: {
    EditableCell,
    draggable,
    oneShuttle,
    filePreview,
    Quickoperationerror,
    PopoverCpn,
  },
  props: {
    // 使用自定义表头
    customHeader: {
      type: Boolean,
      defalt: true,
    },
    // 页码
    pageNum: {
      type: Number,
      defalt: 1,
    },
    // 加载标识
    pictLoading: {
      type: Boolean,
      defalt: false,
    },
    // 每一列数据的 id
    id: {
      type: String,
      default: "id",
    },
    // 是否条纹状表格
    stripe: {
      type: Boolean,
      default: false,
    },
    // 当前视图是否可编辑
    viewModify: {
      type: String,
    },
    // 是否提示无更多数据
    noDataTip: {
      type: Boolean,
      default: false,
    },
    // 是否开启分页
    pagination: {
      type: Boolean,
      default: true,
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
    // 表格数据
    pageObj: {
      type: Object,
      default: null,
    },
    // 可跳转链接是否使用a标签
    useHyperlinks: {
      type: Boolean,
      default: true,
    },
    // 是否展示编辑表格字段
    showTableHeadTool: {
      type: Boolean,
      default: true,
    },
    // 表头
    tableAttr: {
      type: Array,
      default: null,
    },
    // 编辑表头已选字段
    viewSelectedFieldList: {
      type: Array,
      dafault: [],
    },
    // 编辑表头可选字段
    viewUnselectedFieldList: {
      type: Array,
      dafault: [],
    },
    // 数据排序
    sortable: {
      type: Boolean,
      default: false,
    },
    // 选择框
    checked: {
      type: Boolean,
      default: false,
    },
    // 按钮
    tableButton: {
      // 按钮
      type: [Array, Object],
      default: () => [],
    },
    // 操作按钮控制函数
    tableButtonCtrl: {
      type: Function,
      default: undefined,
    },
    // 表格高度
    tableHeight: {
      type: [String, Number, undefined],
      default: undefined,
    },
    // 是否开启边框
    border: {
      type: Boolean,
      default: false,
    },
    // 选中数量
    checkedCount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    // 是否开启序号
    showIndex: {
      type: Boolean,
      default: true,
    },
    // 单选框是否可以选中
    selectable: {
      type: [Function, undefined],
      default: undefined,
    },
    selectedList: {
      type: Array, // 已选中列
      default: () => [],
    },
    autoChecked: {
      // 根据传入的id自动选中
      type: Function,
    },
    spanMethod: {
      // 合并列算法
      type: [Function, undefined],
      default: undefined,
    },
    // 是否开启分页记忆选中功能 现版本配合vuex使用
    memory: {
      type: Boolean,
      default: false,
    },
    headerRowClassName: {
      type: [Function, String],
      default: undefined,
    },
    rowClassName: {
      type: [Function, undefined],
      default: undefined,
    },
    // 是否展开新行
    newLine: {
      type: Boolean,
      default: false,
    },
    // 是否设置min-height
    minHeight: {
      type: Boolean,
      default: false,
    },
    // 对象id，判断对象是否有编辑、删除权限
    objId: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 视图id
    viewId: {
      type: String,
      default: "",
    },
    // 使用父组件传来的objectApi，父组件使用路由传递的值
    objectApi: {
      type: String,
      default: "",
    },
    // 是否显示锁定功能
    showLock: {
      type: Boolean,
      default: true,
    },
    // 相关列表的对象信息
    itemObj: {
      type: Object,
      default: () => {},
    },
    // 判断是否是个案下的文章列表
    caseKnowList: {
      type: Boolean,
      default: false,
    },
    resulterror: {
      type: Boolean,
      default: false,
    },
    // 排序字段
    sortField: {
      type: String,
      default: "",
    },
    // 排序方式
    sortDir: {
      type: String,
      default: "",
    },
  },
  data() {
    // this.memoryCheckList = [] // 记忆分页选中列表
    return {
      // 无可用操作
      buttonBoo: false,
      openDelay: 1000, // 表头悬浮提示延迟时间
      showSearchTable: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      fieldId: "",
      multiChecked: false,
      relevantFieldId: "",
      relevantObjId: "",
      relevantObjApi: "",
      relevantPrefix: "",
      relevantObjectApi: "",
      countryCode: $cookies.get("countryCode"),
      showEditTableFieldPopover: false,
      skeletonOptions: {
        active: true,
        row: 18,
        column: 5,
        width: "18vw",
        height: "20px",
        justifyContent: "space-around",
      },
      schemefieldLength: 0, // 评分字段最大分值
      searchField: "",
      errTip: this.$i18n.t("label.vlidaterule.message"),
      field: "", // 要修改的字段名称
      fieldApiName: "", // 要修改的字段ApiName
      changeAll: false, // 是否应用于全部选中数据
      editableCellOptions: [],
      inputType: "", // 要修改数据显示类型
      toChangeValue: null, //要修改的数据
      dialogVisible: false,
      editModeEnabled: false,
      origin: -1, // 选中数据起点
      pin: false, // 是否按住shift键
      dataList: this.pageObj.dataList,
      memoryCheckList: [],
      pageSize: [10, 20, 50, 100],
      showTable: false,
      pageInfo: true,
      checkedList: [],
      columnTooltip: true,
      isSelectList: [],
      selected: [],
      selection: [],
      total: 0, // 数据总条数
      showApply2Others: false,
      nameLableList: [],
      unselectedFieldList: [],
      allSelectedFieldList: [],
      editableCellAttr: null, //单元格编辑字段信息
      dataId: "",
      selectedFieldList: this.viewSelectedFieldList,
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      precision: 0,
      min: 0,
      max: 0,
      objModifyAll: null, // 管理员权限
      objModify: null, // 对象编辑权限
      objDelete: null, // 对象删除权限
      userUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      inlineedit: false, // 全局内联编辑权限
      echoSelected: [],
      index: 1,
      str: "",
      projectId: "",
      // 文件预览参数
      showPreview: false,
      showPreviewData: {},
      previewUrl: "",
      isSaveBtnLoading: false, //保存加载状态
      isKnowledge: "true",
      replyPhoneShow: false,
      countryCodeCall:
        this.$store.state.userInfoObj.language == "zh" ? "CN" : "",
    };
  },
  created() {
    this.userUrl = this.$cookies.get("domainName");
    this.memoryCheckList = this.memoryCheckList.concat(this.selectedList);
    this.getLeadHeat();
    this.inlineedit = localStorage.getItem("inlineedit");
  },
  mounted() {
    // 监听切换菜单时关闭弹框
    this.$bus.$on("hideTool", this.hideToolCallback );
    // 如果表格宽度小于847  分页部分将折行显示 逻辑控制隐藏左边部分
    if (
      document.getElementById("table-panel") &&
      document.getElementById("table-panel").offsetWidth < 847
    ) {
      this.pageInfo = false;
    }
    this.showTable = true;
    // 获取键盘事件
    window.addEventListener("keydown", this.keydownFn);
    window.addEventListener("keyup", this.keyupFn);

    if (this.itemObj === undefined) {
      this.getObjectPermission(this.objId);
    }
    this.replyPhoneShowClick();
  },
  beforeDestroy() {
    this.$bus.$off("hideTool", this.hideToolCallback );
    window.removeEventListener("keydown", this.keydownFn);
    window.removeEventListener("keyup", this.keyupFn);
  },
  methods: {
    hideToolCallback(data){
      this.showEditTableFieldPopover = data;
    },
    keydownFn(code)  {
      // 获取键盘按住事件
      // 判断是否按住shift键，是就把pin值赋为true
      if (code.keyCode === 16 && code.shiftKey) {
        this.pin = true;
      }
    },
    keyupFn(code) {
      // 获取键盘按住事件
      // 判断是否松开shift键，是就把pin值赋为true
      if (code.keyCode === 16) {
        this.pin = false;
      }
    },
    // 电话回复图标判断
    replyPhoneShowClick() {
      let callcenterConf = JSON.parse(localStorage.getItem("callcenterConf"));
      if (callcenterConf?.tooltype) {
        this.replyPhoneShow = true;
      } else {
        this.replyPhoneShow = false;
      }
      // if (this.countryCodeCall == "CN") {
      //   let paramsInfo = {
      //     intergrateType: "tianrun",
      //   };
      //   // 获取呼叫中心设置
      //   callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
      //     if (resInfo.result  && resInfo.data) {
      //       if (resInfo.data.isEnable == "0") {
      //         this.replyPhoneShow = false;
      //       } else if (resInfo.data.isEnable == "1") {
      //         let params = {
      //           bindUserId: localStorage.getItem("userId"),
      //         };
      //         callCenterApi.getBindByCnoOrUserId(params).then((res) => {
      //           if (res.data.length !== 0) {
      //             this.replyPhoneShow = true;
      //           } else {
      //             this.replyPhoneShow = false;
      //           }
      //         });
      //       }
      //     }
      //   });
      // } else {
      //   let paramsInfo = {
      //     intergrateType: "aws",
      //   };
      //   // 获取呼叫中心设置
      //   callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
      //     if (resInfo.result  && resInfo.data) {
      //       if (resInfo.data.isEnable == "0") {
      //         this.replyPhoneShow = false;
      //       } else if (resInfo.data.isEnable == "1") {
      //         this.replyPhoneShow = true;
      //       }
      //     }
      //   });
      // }
    },

    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    // 潜在客户热度设置信息
    getLeadHeat() {
      if (localStorage.getItem("coldLevel") === null) {
        CommonObjApi.getLeadHeat({}).then((res) => {
          localStorage.setItem("coldLevel", Number(res.data.coldLevel) + 1);
          localStorage.setItem("hotLevel", Number(res.data.hotLevel) + 1);

          this.customColors[0].percentage = localStorage.getItem("coldLevel");
          this.customColors[1].percentage = localStorage.getItem("hotLevel");
        });
      } else {
        this.customColors[0].percentage = localStorage.getItem("coldLevel");
        this.customColors[1].percentage = localStorage.getItem("hotLevel");
      }
    },
    // 获取对象权限
    getObjectPermission(objId) {
      this.objModifyAll = true;
      this.objModify = true;
      this.objDelete = true;
    },
    // 强制刷新
    changeTableAttr(tableAttr) {
      this.nameLableList = [];
      tableAttr.forEach((item) => {
        this.nameLableList.push(item.nameLabel);
      });
      this.$nextTick(() => {
        this.$refs.tablePanel && this.$refs.tablePanel.doLayout();
        this.$forceUpdate();
      });
    },
    // 隐藏设置字段工具
    hideTool(scope) {
      this.showEditTableFieldPopover = false;
    },
    // 查找/查找多选
    remoteSearch() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 改变查找/查找多选的值
    changeSelect(row) {
      let options = [];
      let optionValue = [];
      this.showSearchTable = false;

      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue = row.data.id.toString();
      }

      this.editableCellOptions = options;
      this.toChangeValue = { value: optionValue };
    },
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [];
      values.push(this.id);
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 下拉框类型字段显示值处理
    showSelectVal(item, scope) {
      let showContnet = "";
      let option =
        item.options &&
        item.options.find((option) => {
          return option.codekey === scope.row[item.schemefieldName];
        });
      if (option !== undefined) {
        showContnet = option.codevalue;
      } else {
        showContnet = scope.row[item.schemefieldName] || "";
      }
      return showContnet;
    },
    // 保存视图选中字段
    saveSelectedField() {
      this.$refs.oneShuttle.curGroupLists();
      this.selectedFieldList = this.$refs.oneShuttle.viewSelectedFieldList;
      this.$nextTick(() => {
        if (this.selectedFieldList.length > 15) {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_view_to_display_fields")
          );
        } else if (this.selectedFieldList.length === 0) {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_view_notice_atleast1field")
          );
        } else {
          this.showEditTableFieldPopover = false;
          this.$emit("saveSelectedField", this.str);
          this.selectedFieldList = [];
        }
      });
    },
    // 显示编辑按钮
    cellMouseEnter(row, column, cell, event) {
      // 判断是否开启内联编辑
      if(this.$store.state.userInfoObj.enableInlineEdit==='false') return;
      let attr = this.tableAttr.filter((item) => {
        return item.schemefieldName === column.property;
      });
      // 图片、文件字段不可内联编辑
      if (
        this.inlineedit &&
        attr[0] &&
        attr[0].schemefieldType !== "IMG" &&
        attr[0].schemefieldType !== "FL"
      ) {
        // 通过对象编辑权限和字段编辑权限判断是否展示
        attr[0] &&
        attr[0].modify === "true" &&
        (this.objModifyAll || this.objModify) &&
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
          : "";
      }
      // 知识文章行内不能进行列表行内编辑
      if (attr[0] && this.objectApi === "CloudccKArticle") {
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display =
              "none")
          : "";
      }
      // 项目、里程碑、任务、子任务对象--状态不显示列表行内编辑 根据完成百分比动态改变状态
      if (
        attr[0] &&
        (((this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone") &&
          attr[0].apiname === "status") ||
          ((this.objectApi === "cloudccSubtask" ||
            this.objectApi === "cloudccTask") &&
            attr[0].apiname === "task_status"))
      ) {
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display =
              "none")
          : "";
      }
    },
    // 隐藏编辑按钮
    cellMouseLeave(row, column, cell, event) {
      // 判断是否开启内联编辑
      if(this.$store.state.userInfoObj.enableInlineEdit==='false') return;
      cell.getElementsByClassName("editIconImg")[0]
        ? (cell.getElementsByClassName("editIconImg")[0].style.display = "none")
        : "";
    },
    // 查询视图详细信息
    searchViewDetail() {
      this.$emit("searchViewDetail");
    },
    // 修改排序字段
    changeSortFiled(field) {
      if (event.target.tagName !== "I") {
        this.$emit("changeSortFiled", field);
      }
    },
    // 日期格式化
    formatDate(date) {
      let dateStr = "";
      let dateRes = new Date(date);
      const Y = dateRes.getFullYear();
      const M =
        dateRes.getMonth() + 1 < 10
          ? "0" + (dateRes.getMonth() + 1)
          : dateRes.getMonth() + 1;
      const D = dateRes.getDate();
      if (isNaN(date) && !isNaN(Date.parse(date))) {
        dateStr = `${Y}/${M}/${D}`;
      }
      return dateStr;
    },
    // 日期时间格式化
    formatDateTime(date) {
      let dateStr = "";
      let dateRes = new Date(date);
      const Y = dateRes.getFullYear();
      const M =
        dateRes.getMonth() + 1 < 10
          ? "0" + (dateRes.getMonth() + 1)
          : dateRes.getMonth() + 1;
      const D = dateRes.getDate();
      const h =
        dateRes.getHours() < 10 ? `0${dateRes.getHours()}` : dateRes.getHours();
      const m =
        dateRes.getMinutes() < 10
          ? `0${dateRes.getMinutes()}`
          : dateRes.getMinutes();
      const s =
        dateRes.getSeconds() < 10
          ? `0${dateRes.getSeconds()}`
          : dateRes.getSeconds();
      if (isNaN(date) && !isNaN(Date.parse(date))) {
        dateStr = `${Y}/${M}/${D} ${h}:${m}:${s}`;
      }
      return dateStr;
    },
    // 自动换行
    wordWrap() {
      this.$emit("wordWrap", ...arguments);
    },
    // 锁定
    locked(item) {
      // 关闭锁定气泡
      this.$refs[`${item.schemefieldName}-popover`][0].doClose();
      this.$refs[`${item.schemefieldName}-popover`][1].doClose();
      this.$emit("locked", ...arguments);
    },
    // 文件下载
    downLoadFile(file) {
      let baseURL = this.$baseConfig.baseURL;
      let token = this.$CCDK.CCToken.getToken();
      var link = document.createElement("a");
      link.setAttribute("download", "");
      //相关列表需要的文件下载
      if (this.objId && this.objId === "attachement") {
        link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${file.id}`;
      } else {
        //视图列表的文件下载
        link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${
          file.fileid || file.file_info_id || file.id
        }`;
      }
      link.click();
    },
    // 名称字段跳转路径
    nameHrefUrl(row) {
      //如果是服务报告、推送编号则禁止跳转
      if (
        this.objectApi == "servicereport" ||
        this.objectApi == "ServiceReportPushRecord"
      ) {
        return `javascript:void(0);`;
      } else {
        return row.id ? `#/commonObjects/detail/${row.id}/DETAIL` : "";
      }
    },
    // 所有人字段跳转路径
    hrefUrl(row, item) {
      // 项目管理-实际工作清单点击日期跳转详情
      if (
        this.$cookies.get("activeTabObjId") === "projectworklist" &&
        item.schemefieldType === "D"
      ) {
        return row[item.schemefieldName]
          ? `#/commonObjects/detail/${row.id}/DETAIL`
          : "";
      } else if (item.apiname === "slaprocessid") {
        //如果是权利过程则禁止跳转
        return `javascript:void(0);`;
      } else {
        return row[item.schemefieldName]
          ? `#/commonObjects/detail/${row[item.schemefieldName]}/DETAIL`
          : "";
      }
    },
    // url跳转
    jumpToUrl(url) {
      window.open(url);
    },
    // 进度条格式化
    formatProgress(percentage) {
      return "";
    },
    // 表格滚动懒加载
    append(params) {
      this.$emit("append");
    },
    reload() {
      // 刷新重置 offset 和表格
      this.params.offset = 0;
      this.tableData = [];

      // api动态加载完 开始重新请求数据
      this.$nextTick(() => {
        this.init(this.params);
      });
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // 滚动事件
    loadMore() {
      let that = this;
      // let dom = document.querySelector('.el-table__body-wrapper')
      let dom = document.getElementsByClassName("el-table__body-wrapper")[
        document.getElementsByClassName("el-table__body-wrapper").length - 1
      ];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        // 解决表格滚动时左右对不齐问题
        if (scrollDistance <= 30) {
          that.$refs.tablePanel && that.$refs.tablePanel.doLayout();
        }

        if (scrollDistance <= 0) {
          //等于0证明已经到底，可以请求接口
          if (!this.noDataTip) {
            that.fnThrottle(that.append, 500, 1000)();
          }
        }
      });
    },
    // 表格滚动懒加载
    clearSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.tablePanel.toggleRowSelection(row);
        });
      } else {
        this.$refs.tablePanel.clearSelection();
      }
    },
    clearSelect() {
      this.selected = [];
    },
    // 拖拽开始事件
    startDrag(evt) {
      return false;
    },
    // 表头操作
    handleAction(index, row, action) {
      if (arguments.length === 1) {
        action = index;
        this.$emit(action, action);
      } else {
        if (action === "handleDel") {
          this.$confirm(
            "此操作将永久删除该文件, 是否继续?",
            this.$i18n.t("label.prompt"),
            {
              confirmButtonText: this.$i18n.t("label.confirm"),
              cancelButtonText: this.$i18n.t("label.cancel"),
              type: "warning",
            }
          )
            .then(() => {
              this.$emit(action, index, row);
            })
            .catch(() => {
              // ;
            });
        } else {
          this.$emit(action, index, row);
        }
      }
    },
    // 按钮权限判断
    ifButtonShow(rowData, btn, tableButton) {
      if (
        btn.label === this.$i18n.t("pagecreator_page_button_edit") &&
        (this.objModifyAll || this.objModify)
      ) {
        return true;
      } else if (
        btn.label === this.$i18n.t("component_setup_tabs_label_delete") &&
        (this.objModifyAll || this.objDelete)
      ) {
        return true;
      } else if (
        btn.label !== this.$i18n.t("pagecreator_page_button_edit") &&
        btn.label !== this.$i18n.t("component_setup_tabs_label_delete") &&
        (this.objModifyAll || this.objModify)
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 表格拖动列时触发的操作
    headerDragend(newWidth, oldWidth, column, event) {
      let fieldId = "";
      this.tableAttr.forEach((attr) => {
        if (attr.schemefieldName === column.property) {
          fieldId = attr.id;
        }
      });
      let params = {
        viewId: this.viewId,
        fieldId: fieldId,
        colwidth: Math.round(newWidth),
      };
      if (
        this.viewId === "0" ||
        this.viewId === "1" ||
        this.viewId === "2" ||
        this.viewId === "3"
      ) {
        params.viewId = `${this.prefix}_${this.viewId}`;
      }
      if (this.viewId !== "") {
        CommonObjApi.updateViewFieldMemory(JSON.stringify(params)).then(
          (res) => {
            if (res.result && res.returnCode === "1") {
            } else {
              // this.$message.error(res.returnInfo);
              // return false;
            }
          }
        );
      }
      // this.$nextTick(() => {
      //   this.$refs.tablePanel.doLayout()
      // })
    },
    // 列表中操作
    handleTableAction(data, action) {
      let idx = data.$index + 1;
      let rowData = data.row;
      let actionName = action.action;
      let emitData = {
        index: idx, // 当前点击位置
        data: rowData, // 当前点击数据
        action: actionName, // 执行当前动作名称
        type: action.type || undefined, // 当前操作类别
        val: action.val || undefined, // 点击当前按钮派发的自定义值
      };
      // 若无管理员权限，判断按钮权限
      if (false) {
        this.$emit(actionName, emitData);
      } else if (data.row.attachtype) {
        this.$emit(actionName, emitData);
      } else if (this.caseKnowList === true) {
        if (action.action === "Additionalclose") {
          this.$emit(actionName, emitData);
        } else if (action.action === "draftedit") {
          this.$emit("dialogAssembly", emitData, "draftedit");
        } else if (action.action === "edit") {
          this.$emit(actionName, emitData);
        }
      } else {
        CommonObjApi.getPermissionById({ id: data.row.id }).then((res) => {
          if (action.action === "del") {
            // 非锁定状态且有编辑权限时可删除
            if (res.data.isDelete && !res.data.isLocked) {
              this.$emit(actionName, emitData);
            } else {
              this.$message({
                showClose: true,
                message: this.$i18n.t("vue_label_notice_nopermission_delete"),
                type: "warning",
              });
              //抱歉，您无权删除该条数据，请联系系统管理员。
            }
          } else if (action.action === "edit") {
            if (res.data.isEdit) {
              this.$emit(actionName, emitData);
            } else {
              this.$message.warning(
                // "抱歉，您无权编辑该条数据，请联系系统管理员。"
                this.$i18n.t("report_label_norole")
              );
            }
          } else if (action.action == "draftedit") {
            //作为草稿编辑
            this.$emit("dialogAssembly", emitData, "draftedit");
          } else if (action.action == "assign") {
            //指派
            this.$emit(actionName, emitData);
          } else if (action.action == "release") {
            //发布
            this.$emit(actionName, emitData);
          } else if (action.action == "draftdel") {
            //删除草稿
            if (res.data.isDelete) {
              this.$emit("dialogAssembly", emitData, "draftdel");
            } else {
              this.$message({
                showClose: true,
                message: this.$i18n.t("vue_label_notice_nopermission_delete"),
                type: "warning",
              });
              //抱歉，您无权删除该条数据，请联系系统管理员。
            }
          } else if (action.action == "articledel") {
            //删除文章
            if (res.data.isDelete) {
              this.$emit("dialogAssembly", emitData, "articledel");
            } else {
              this.$message({
                showClose: true,
                message: this.$i18n.t("vue_label_notice_nopermission_delete"),
                type: "warning",
              });
              //抱歉，您无权删除该条数据，请联系系统管理员。
            }
          } else if (action.action == "changerecordtype") {
            //更改数据类型
            this.$emit(actionName, emitData);
          } else if (action.action == "file") {
            //归档
            this.$emit("dialogAssembly", emitData, "file");
          } else if (action.action == "recovery") {
            //恢复
            this.$emit("dialogAssembly", emitData, "recovery");
          } else if (action.action == "submitapproval") {
            this.$emit(actionName, emitData);
          } else if (action.action == "submittranslation") {
            //提交翻译
            this.$emit(actionName, emitData);
          }
        });
      }
    },
    // 自定义序号序列化
    indexMethod(idx) {
      idx += 1;
      let page = 0;
      if (this.pageObj.page && this.pageObj.limit) {
        page = (this.pageObj.page - 1) * this.pageObj.limit;
      }
      return page + idx;
    },
    // 编辑单元格
    editCell(item, row) {
      //项目管理行内编辑根据项目对里程碑、任务、成员做限制
      this.projectId = row.project_name || row.their_project;
      // 阻止内联编辑按钮冒泡事件
      event.preventDefault();

      // 根据Id获取数据权限
      CommonObjApi.getPermissionById({ id: row.id }).then((res) => {
        if (res.data.isEdit) {
          // 点击单元格内编辑图标时不跳转
          this.dataId = row.id;
          this.changeAll = false;
          this.editableCellOptions = [];
          this.inputType = INPUTTYPE[item.schemefieldType];
          this.showApply2Others = this.checkedList.length > 1 ? true : false;
          // 列表点击日期编辑处理
          if (item.type === "D") {
            this.toChangeValue = { value: new Date(row[item.schemefieldName]) };
          } else {
            this.toChangeValue = { value: row[item.schemefieldName] };
          }
          this.editableCellAttr = item;
          // 复选框类型无返回值时
          if (item.schemefieldType === "B") {
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined
                  ? false
                  : row[item.schemefieldName] === "true",
            };
          } else if (
            item.schemefieldType === "X" &&
            item.apiname === "cloudcctag"
          ) {
            // 标签
            this.inputType = "tag";
            this.toChangeValue = { value: row["cloudcctagList"] };
          }
          // 查找、主详、查找多选字段
          else if (
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR"
          ) {
            this.relevantFieldId = item.id;
            this.relevantObjId = item.lookupObj;
            this.multiChecked = item.schemefieldType === "MR" ? true : false;
            // this.relevantPrefix = ''
            this.editableCellOptions = [];
            if (
              row[item.schemefieldName] &&
              row[`${item.schemefieldName}ccname`] &&
              item.schemefieldType !== "MR"
            ) {
              this.editableCellOptions = [
                {
                  label: row[`${item.schemefieldName}ccname`],
                  value: row[item.schemefieldName],
                },
              ];
            } else if (
              row[item.schemefieldName] &&
              row[item.schemefieldName] !== "" &&
              row[`${item.schemefieldName}ccname`] &&
              item.schemefieldType === "MR"
            ) {
              let values = row[item.schemefieldName].split(";");
              let labels = row[`${item.schemefieldName}ccname`].split(";");
              values.forEach((item, idx) => {
                this.editableCellOptions.push({
                  label: labels[idx],
                  value: values[idx],
                });
              });
              this.toChangeValue = { value: values };
            }
          } else if (
            item.schemefieldType === "L" ||
            item.schemefieldType === "Q" ||
            item.schemefieldType === "ct"
          ) {
            // 选择/多选/币种类型
            this.editableCellOptions = [];
            item.options.forEach((ele) => {
              this.editableCellOptions.push({
                label: ele.codevalue,
                value: ele.codekey,
              });
            });
          } else if (this.inputType === "number") {
            this.precision = item.decimalPlaces
              ? Number(item.decimalPlaces)
              : 0;
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined ||
                row[item.schemefieldName] === null
                  ? row[item.schemefieldName]
                  : row[item.schemefieldName].replace(/,/g, ""),
            };
            // 币种字段可能带币种类型，特殊处理
            if (item.schemefieldType === "c") {
              let changeValue = 0;
              if (
                row[item.schemefieldName] !== undefined &&
                row[item.schemefieldName] !== null
              ) {
                changeValue =
                  row[item.schemefieldName].indexOf("(") !== -1
                    ? row[item.schemefieldName].split("(")[0]
                    : row[item.schemefieldName];
                changeValue =
                  changeValue.indexOf(" ") !== -1
                    ? changeValue
                        .trim()
                        .split(" ")
                        [changeValue.trim().split(" ").length - 1].replace(
                          /,/g,
                          ""
                        )
                    : changeValue;
              }
              this.toChangeValue = { value: changeValue };
            }
            // 控制内联编辑为null时不显示0
            this.toChangeValue.value =
              this.toChangeValue.value === null
                ? undefined
                : this.toChangeValue.value;

            this.min =
              0 -
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) +
              1;
            this.max =
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) - 1;
          } else if (this.inputType === "percent-number") {
            this.precision = Number(item.decimalPlaces);
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined ||
                row[item.schemefieldName] === null
                  ? row[item.schemefieldName]
                  : row[item.schemefieldName].replace(/,/g, ""),
            };
            // 控制内联编辑为null时不显示0
            this.toChangeValue.value =
              this.toChangeValue.value === null
                ? undefined
                : this.toChangeValue.value;

            this.min =
              0 -
              Math.pow(
                10,
                Number(item.schemefieldLength) - Number(item.decimalPlaces) - 1
              ) +
              1;
            this.max =
              Math.pow(
                10,
                Number(item.schemefieldLength) - Number(item.decimalPlaces) - 1
              ) - 1;
          } else if (this.inputType === "score") {
            this.schemefieldLength = Number(item.schemefieldLength);
            this.toChangeValue = { value: Number(row[item.schemefieldName]) };
          }
          this.field = item.nameLabel;
          this.fieldApiName = item.apiname;
          this.dialogVisible = true;
          this.$refs.editableCell && this.$refs.editableCell.setInputValue();
        } else {
          this.$message.warning("抱歉，您无权编辑该条数据，请联系系统管理员。");
        }
      });
    },
    // 表格被双击
    cellDoubleClick(row, column, cell, event) {
      // 判断是否开启内联编辑
      if(this.$store.state.userInfoObj.enableInlineEdit==='false') return;
      // 附件不需要进行权限校验
      if (
        this.$route.params.type === "file" ||
        (this.itemObj && this.itemObj.relatedlistType === "attachement")
      ) {
        return;
      }
      // 查找要显示的数据类型
      let attr = this.tableAttr.filter((item) => {
        return item.schemefieldName === column.property;
      });
      // 事件及任务下的名称及相关项去除双击编辑
      if (
        attr[0].apiname === "relateobj" ||
        attr[0].apiname === "relateid" ||
        attr[0].apiname === "whoobj" ||
        attr[0].apiname === "whoid"
      ) {
        return false;
      }
      // 知识文章行内不能进行双击编辑
      if (
        attr[0] &&
        this.objectApi === "CloudccKArticle"
        // (attr[0].apiname === "language" ||
        //   attr[0].apiname === "last_publish_date" ||
        //   attr[0].apiname === "publish_status" ||
        //   attr[0].apiname === "version_number" ||
        //   attr[0].apiname === "article_number")
      ) {
        return false;
      }
      // 项目、里程碑、任务、子任务对象--状态不显示列表行内编辑 根据完成百分比动态改变状态
      if (
        attr[0] &&
        (((this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone") &&
          attr[0].apiname === "status") ||
          ((this.objectApi === "cloudccSubtask" ||
            this.objectApi === "cloudccTask") &&
            attr[0].apiname === "task_status"))
      ) {
        return false;
      }
      // 判断对象编辑权限、字段编辑权限、记录编辑权限
      if (
        attr[0] &&
        attr[0].modify === "true" &&
        (this.objModifyAll || this.objModify) &&
        this.inlineedit
      ) {
        CommonObjApi.getPermissionById({ id: row.id }).then((res) => {
          if (res.data.isEdit) {
            this.dataId = row.id;
            this.changeAll = false;
            this.editableCellOptions = [];
            this.showApply2Others = this.checkedList.length > 1 ? true : false;

            this.editableCellAttr = attr[0];
            // 图片、文件字段不可内联编辑
            if (
              attr[0].schemefieldType !== "IMG" &&
              attr[0].schemefieldType !== "FL"
            ) {
              if (attr[0].modify === "true") {
                this.inputType = INPUTTYPE[attr[0].schemefieldType];
                this.toChangeValue = { value: row[column.property] };
                // 复选框类型无返回值时
                if (
                  attr[0].schemefieldType === "B" &&
                  row[column.property] === undefined
                ) {
                  this.toChangeValue = {
                    value:
                      row[column.property] === undefined
                        ? false
                        : row[column.property] === "true",
                  };
                }
                // 标签
                else if (
                  attr[0].schemefieldType === "X" &&
                  attr[0].apiname === "cloudcctag"
                ) {
                  this.inputType = "tag";
                  this.toChangeValue = { value: row["cloudcctagList"] };
                }
                // 查找、主详、查找多选字段
                else if (
                  attr[0].schemefieldType === "Y" ||
                  attr[0].schemefieldType === "M" ||
                  attr[0].schemefieldType === "MR"
                ) {
                  this.relevantFieldId = attr[0].id;
                  this.relevantObjId = attr[0].lookupObj;
                  this.multiChecked =
                    attr[0].schemefieldType === "MR" ? true : false;
                  this.editableCellOptions = [];
                  if (
                    row[column.property] &&
                    row[`${column.property}ccname`] &&
                    attr[0].schemefieldType !== "MR"
                  ) {
                    this.editableCellOptions = [
                      {
                        label: row[`${column.property}ccname`],
                        value: row[column.property],
                      },
                    ];
                  } else if (
                    row[column.property] &&
                    row[column.property] !== "" &&
                    row[`${column.property}ccname`] &&
                    attr[0].schemefieldType === "MR"
                  ) {
                    let values = row[column.property].split(";");
                    let labels = row[`${column.property}ccname`].split(";");
                    values.forEach((item, idx) => {
                      this.editableCellOptions.push({
                        label: labels[idx],
                        value: values[idx],
                      });
                    });
                    this.toChangeValue = { value: values };
                  }
                }
                // 选项列表、选项列表多选
                else if (
                  attr[0].schemefieldType === "L" ||
                  attr[0].schemefieldType === "Q" ||
                  attr[0].schemefieldType === "ct"
                ) {
                  this.editableCellOptions = [];
                  attr[0].options.forEach((item) => {
                    this.editableCellOptions.push({
                      label: item.codevalue,
                      value: item.codekey,
                    });
                  });
                } else if (this.inputType === "number") {
                  this.precision = Number(attr[0].decimalPlaces);
                  this.toChangeValue = {
                    value:
                      row[column.property] === undefined ||
                      row[column.property] === null
                        ? row[column.property]
                        : row[column.property].replace(/,/g, ""),
                  };

                  // 币种字段可能带币种类型，特殊处理
                  if (attr[0].schemefieldType === "c") {
                    let changeValue = 0;
                    if (
                      row[column.property] !== undefined &&
                      row[column.property] !== null
                    ) {
                      changeValue =
                        row[column.property].indexOf("(") !== -1
                          ? row[column.property].split("(")[0]
                          : row[column.property];
                      changeValue =
                        changeValue.indexOf(" ") !== -1
                          ? changeValue
                              .trim()
                              .split(" ")
                              [
                                changeValue.trim().split(" ").length - 1
                              ].replace(/,/g, "")
                          : changeValue;
                    }
                    this.toChangeValue = { value: changeValue };
                  }
                  // 控制内联编辑为null时不显示0
                  this.toChangeValue.value =
                    this.toChangeValue.value === null
                      ? undefined
                      : this.toChangeValue.value;

                  this.min =
                    0 -
                    Math.pow(
                      10,
                      attr[0].schemefieldLength - attr[0].decimalPlaces - 1
                    ) +
                    1;
                  this.max =
                    Math.pow(
                      10,
                      attr[0].schemefieldLength - attr[0].decimalPlaces - 1
                    ) - 1;
                } else if (this.inputType === "percent-number") {
                  this.precision = Number(item.decimalPlaces);
                  this.toChangeValue = {
                    value:
                      row[column.property] === undefined ||
                      row[column.property] === null
                        ? row[column.property]
                        : row[column.property].replace(/,/g, ""),
                  };
                  // 控制内联编辑为null时不显示0
                  this.toChangeValue.value =
                    this.toChangeValue.value === null
                      ? undefined
                      : this.toChangeValue.value;

                  this.min =
                    0 -
                    Math.pow(
                      10,
                      Number(item.schemefieldLength) -
                        Number(item.decimalPlaces) -
                        1
                    ) +
                    1;
                  this.max =
                    Math.pow(
                      10,
                      Number(item.schemefieldLength) -
                        Number(item.decimalPlaces) -
                        1
                    ) - 1;
                } else if (this.inputType === "score") {
                  this.schemefieldLength = Number(item.schemefieldLength);
                  this.toChangeValue = {
                    value: Number(row[item.schemefieldName]),
                  };
                }
                this.field = attr[0].nameLabel;
                this.fieldApiName = attr[0].apiname;
                this.dialogVisible = true;
                this.$refs.editableCell &&
                  this.$refs.editableCell.setInputValue();
              }
            }
          } else {
            this.$message.warning(this.$i18n.t("report_label_norole"));
          }
        });
      }
    },
    // 保存修改字段
    saveChange() {
      let fields = [];
      let datas = [];
      // TODO:查找和查找多选类型字段值修改
      let value = this.$refs.editableCell.editValue;
      //列表行内编辑-项目管理系统合同金额
      if (this.editableCellAttr.apiname === "contract_amount") {
        let _value = value + "";
        if (_value.length > 10) {
          // 合同金额最多输入10位
          this.$message.warning(
            this.$i18n.t(
              "label.projectManagement.the.maximum.contract.amount.is.10.digits"
            )
          );
          return;
        }
      }
      //列表行内编辑-项目管理系统 任务工时小时数
      if (this.editableCellAttr.apiname === "daily_working_hours") {
        let _value1 = value;
        if (_value1 > 24 || _value1 <= 0) {
          // 任务工时小时数必须小于24
          this.$message.warning(
            this.$i18n.t("label.projectManagement.positive.integer")
          ); //"请输入0-24之间的正整数"
          return;
        }
      }
      //列表行内编辑-实际工作清单的实际工作时长
      if (this.editableCellAttr.apiname === "working_hours_billing") {
        let _value2 = value;
        if (_value2 > 24 || _value2 <= 0) {
          // 任务工时小时数必须小于24
          this.$message.warning(
            this.$i18n.t("label.projectManagement.positive.integer")
          ); //"请输入0-24之间的正整数"
          return;
        }
      }
      this.tableAttr.forEach((item) => {
        // 字段apiname取apiname或者schemefieldName不定
        fields.push(item.apiname || item.schemefieldName);
      });

      if (this.checkedList.length > 1 && this.changeAll) {
        this.checkedList.forEach((item) => {
          let data = { id: item.id };
          data[
            this.editableCellAttr.apiname ||
              this.editableCellAttr.schemefieldName
          ] = value === null || value === undefined ? value : value.toString();
          datas.push(data);
        });
      } else {
        let data = { id: this.dataId };
        data[
          this.editableCellAttr.apiname || this.editableCellAttr.schemefieldName
        ] = value === null || value === undefined ? value : value.toString();
        datas.push(data);
      }

      if (datas.length > 200) {
        // 最多应用于200条数据
        this.$message.warning(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        );
      } else if (
        this.fieldApiName === "twitter" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("twitter.com") === -1
      ) {
        // twitter、LinkedIn、Facebook字段加验证，必须带twitter.com、linkedin.com、facebook.com
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.twittererror")
        );
      } else if (
        this.fieldApiName === "linkedin" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("linkedin.com") === -1
      ) {
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.linkedinerror")
        );
      } else if (
        this.fieldApiName === "facebook" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("facebook.com") === -1
      ) {
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.facebookerror")
        );
      } else {
        //编辑项目任务列表
        if (
          window.location.href.substring(window.location.href.length - 3) ==
          "p03"
        ) {
          // 按钮加载中
          this.isSaveBtnLoading = true;
          CommonObjApi.getPermissionById({ id: datas[0].id })
            .then((res) => {
              let params = {
                objectApi: res.data.objectApi,
                jsonstr: JSON.stringify(datas),
                fields: fields.toString(),
              };
              // if (value !== null && value !== undefined && value.toString().trim() != "") {
              CommonObjApi.updateViewListAjax(params)
                .then((res) => {
                  this.isSaveBtnLoading = false;
                  res.data.updateResultList.forEach((updateResult) => {
                    if (updateResult.errorMessage) {
                      this.$message.error(updateResult.errorMessage);
                      return;
                    } else {
                      this.$message.success(
                        this.$i18n.t(
                          "label.setup.mobile.bottombutton.change.success"
                        )
                      ); //"修改成功"
                      this.$emit("refresh");
                      this.checkedList = [];
                      this.dialogVisible = false;
                    }
                  });
                })
                .catch((err) => {
                  this.isSaveBtnLoading = false;
                });
            })
            .catch((err) => {
              this.isSaveBtnLoading = false;
            });
        } else {
          let params = {
            objectApi: this.objectApi,
            jsonstr: JSON.stringify(datas),
            fields: fields.toString(),
          };
          //输入值为空时不可保存
          // if (value !== null && value !== undefined && value.toString().trim() != "") {
          this.isSaveBtnLoading = true;

          CommonObjApi.updateViewListAjax(params)
            .then((res) => {
              this.isSaveBtnLoading = false;
              const data = this.$refs.tablePanel.tableData;
              if (
                res.data !== null &&
                res.data.list &&
                res.data.list.length > 0
              ) {
                res.data.list.forEach((changeItem) => {
                  data.forEach((item, idx) => {
                    if (item.id === changeItem.id && item.name !== "") {
                      this.$set(
                        this.$refs.tablePanel.tableData,
                        idx,
                        changeItem
                      );
                    }
                  });
                });
              }
              // 处理提示语
              let returnInfo = "";
              res.data.updateResultList.forEach((updateResult) => {
                if (updateResult.isSuccess !== "true") {
                  returnInfo += this.$i18n.t(
                    "vue_label_notice_batchedit_fail",
                    [updateResult.id, updateResult.errorMessage]
                  );
                  // `id为${updateResult.id}的数据更新失败，失败原因为${updateResult.errormessage};`;
                }
              });
              if (returnInfo === "") {
                //群策卡片
                //
                this.$message.success(
                  this.$i18n.t("vue_label_notice_change_success")
                );
                // 清空选中数据
                this.checkedList = [];
                this.dialogVisible = false;
                this.$refs.tablePanel.clearSelection();
              } else {
                this.$message.error(returnInfo);
              }
            })
            .catch((err) => {
              this.isSaveBtnLoading = false;
            });
        }
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 内联编辑内容改变
    handleChange() {
      this.$emit("handleChange", ...arguments);
    },
    // 打开预览
    openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = {
        name: "pdf",
        type: "pdf",
        id: item,
      };
      let arr = [];
      this.$refs.previewFile.handleBtn(arr);
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false;
    },
    // 跳转事件
    jumpTo(item, scope, cb) {
      //如果当前对象API为服务报告则执行此方法
      if (this.objectApi == "servicereport") {
        if (scope.row.fileid) {
          this.openPreview(scope.row.fileid);
        } else {
          this.$message.warning(this.$i18n.t("label.file.publicview.error"));
        }
        return;
      }
      // 判断服务云下的权利下的权利过程根据简档权限跳转lightning设置
      if (item.apiname === "slaprocessid") {
        if (this.$store.state.userInfoObj.profileId === "aaa000001") {
          const newPage = this.$router.resolve({
            path: "/setuppage/Administration",
            query: {
              isGoDetail: true,
              goDetailId: scope.row.slaprocessid,
            },
          });
          window.open(newPage.href, "_blank");
        } else {
          // 抱歉，您无权查看该条数据，请联系系统管理员。
          this.$message.warning(
            this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
          );
        }
        return;
      }
      if (event.target.tagName !== "IMG") {
        let idx = scope.$index + 1;
        let rowData = scope.row;
        let emitData = {
          field: item,
          index: idx, // 当前点击位置
          data: rowData, // 当前点击数据
        };
        this.$emit(cb, emitData);
      }
      // cb && cb(scope)
    },
    // 单选事件
    select(selection, row) {
      // shift选中使用

      const data = this.$refs.tablePanel.tableData; // 获取所有数据
      const origin = this.origin; // 起点数 从-1开始
      const endIdx =
        selection[selection.length - 1] &&
        selection[selection.length - 1].index; // 终点数

      // 判断设置成选中还是未选中
      this.checkedList = selection;
      if (this.pin && selection.includes(data[origin])) {
        // 判断按住
        const sum = Math.abs(origin - endIdx) + 1; // 这里记录终点
        const min = Math.min(origin, endIdx); // 这里记录起点
        let i = 0;
        while (i < sum) {
          const index = min + i;
          let checked = this.checkedList.find((checked) => {
            return checked.id === data[index].id;
          });

          if (checked === undefined) {
            this.checkedList.push(data[index]);
          }
          this.$refs.tablePanel.toggleRowSelection(data[index], true); // 通过ref打点调用toggleRowSelection方法，第二个必须为true
          i++;
        }
      } else {
        //
        this.origin = row.index; // 记录起点
      }
    },
    rowClick(row, column, event) {
      this.$emit("rowClick", row, column);
    },
    // 全选事件
    selectAll(selection) {
      this.$emit("onchange", selection);
      // 如果全选 将全部列表元素插入到已选列表 否则全部删除列表
      let copyArr = selection;
      // selection = this.pageObj.dataList
      selection = this.dataList;
      selection.forEach((row) => {
        this.handlerChecked(row, !emptyArray(copyArr));
      });
      // 可能修改: 在调用getCheckedList时返回this.checkedList,为了在全选时也能使用
      this.checkedList =
        this.checkedList.length === this.pageObj.dataList.length
          ? []
          : selection;

      this.$emit("selectAll", selection, this.memoryCheckList);
      this.handleSelect(selection);
    },
    /**
     * 添加人：张亚欣
     * 添加原因：记录分页选中数据
     * **/
    handleSelect(val) {
      const hasSave = this.selected.find((item) => {
        return item.page === this.pageObj.page;
      });
      if (hasSave) {
        hasSave.rows = this.dataList.filter((item) => {
          return val.includes(item);
        });
      } else {
        this.selected.push({
          page: this.pageObj.page,
          rows: val,
        });
      }
    },
    // 处理全选反选中的冗余代码
    handlerChecked(row, isNotAllCheck) {
      if (row.id) {
        let id = row.id;
        let idx = this.memoryCheckList.indexOf(id);
        // 如果不存在 加入
        if (idx === -1 && !isNotAllCheck) {
          this.memoryCheckList.push(id);
        }
        // 如果存在 直接删除
        if (idx > -1 && isNotAllCheck) {
          this.memoryCheckList.splice(idx, 1);
        }
      }
    },
    sortBy(props) {
      return function (a, b) {
        return a[props] - b[props];
      };
    },
    // 表格选中状态
    selectionChange(rows) {
      this.$emit("onchange", this.checkedList);
      const data = this.$refs.tablePanel.tableData;
    },
    // 获取被选中列表
    getCheckedList() {
      // 如果开启分页记录 memorycheckList中的所有数据
      if (this.memory) {
        // 将当前页的数据保存 memorycheckList中 防止bug
        return this.memoryCheckList;
      } else {
        return this.checkedList;
      }
    },
    clearMemoryList() {
      this.memoryCheckList = [];
    },
    toggleSelection() {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.tablePanel.toggleRowSelection(row);
        });
      } else {
        this.$refs.tablePanel.clearSelection();
      }
    },
    getSelected() {
      if (this.selected.length === 0) {
        return [];
      }
      let result = [];
      this.selected.forEach((item) => {
        result = [...result, ...item.rows];
      });
      return result;
    },
    echo(val) {
      this.echoSelected = val;
    },
    unique(arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].id === arr[j].id) {
            arr.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return arr;
    },
    memberFun(val) {
      this.str = val;
    },
    //取消可选字段组件的icon
    hideBtn() {
      if (this.$refs.oneShuttle !== undefined) {
        this.$refs.oneShuttle.hideBtn();
      }
    },
    //跳转到记录详情
    jumpDetail(item) {
      this.itemHref = item;
      if (item.objid === "lightningreport") {
        // 属于报表
        this.$router.push({
          path: `/reportObject/editReport/run/${item.recordid}`,
          query: {},
        });
      } else if (item.objid === "dashboardlightning") {
        // 属于仪表板
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${item.recordid}`,
          query: {},
        });
      } else if (item.objid === "file") {
        //属于文件
        this.$router.push({
          path: "/ccFileCenter/fileDetail",
          query: {
            id: item.recordid,
          },
        });
      } else {
        // 属于通用对象
        this.$router.push({
          path: `/commonObjects/detail/${item.id}/DETAIL`,
        });
      }
    },

    //  电话回复
    callPhone(phone) {
      this.$bus.$emit("newCLick", phone);
    },
  },
  computed: {
    userCheckedCount() {
      return this.memory ? this.selectedList.length : this.checkedList.length;
    },
  },
  watch: {
    // 监听弹框的是否为true，true时清空字段装下标的数组
    showEditTableFieldPopover() {
      if (this.showEditTableFieldPopover == true) {
        this.$bus.$emit("clearIndexArr");
      }
    },
    searchField(nval, oval) {
      setTimeout(() => {
        if (nval === "") {
          this.unselectedFieldList = this.allSelectedFieldList;
        } else {
          this.unselectedFieldList = this.allSelectedFieldList.filter(
            (item) => {
              return item.label.indexOf(nval) !== -1;
            }
          );
        }
      }, 100);
    },
    unselectedFieldList: {
      deep: true,
      handler(nval) {
        nval.length > 0 &&
          nval.forEach((field) => {
            let isExist = false;
            this.allSelectedFieldList.forEach((item) => {
              if (item.id === field.id) {
                isExist = true;
              }
            });
            if (!isExist) {
              this.allSelectedFieldList.push(field);
            }
          });
      },
    },
    selectedFieldList: {
      deep: true,
      handler(nval) {
        nval.length > 0 &&
          nval.forEach((field) => {
            this.allSelectedFieldList = this.allSelectedFieldList.filter(
              (item) => {
                return item.id !== field.id;
              }
            );
            //
          });
      },
    },
    viewSelectedFieldList: {
      handler(nval) {
        this.selectedFieldList = nval;
      },
      deep: true,
    },
    pageObj: {
      // 分页数据发生改变时候选中选项框
      handler: function (oldVal, newVal) {
        // 定时器确保表格已经刷新
        setTimeout(() => {
          // 数据更新
          this.dataList = this.pageObj.dataList;
          this.dataList &&
            this.dataList.forEach((item, index) => {
              item.index = index;
            });
          // 打印传递数据
          this.$nextTick(() => {
            //解决第一次触发emit无效问题
            this.$store.state.printList = this.dataList;
          });

          // 创建选中
          if (!newVal || !newVal.dataList) {
            return false;
          }
          // 记录上次选中数据
          // TODO:修改选中之前的数据状态
          newVal.dataList.forEach((item) => {
            if (item.checked !== undefined) {
              this.$refs.tablePanel.toggleRowSelection(item);
            }
          });
        }, 20);
      },
      deep: true,
    },
    viewUnselectedFieldList: {
      handler(nval) {
        this.allSelectedFieldList = nval;
        this.unselectedFieldList = nval;
      },
      deep: true,
    },
    objId(nVal, oVal) {
      if (this.itemObj === undefined) {
        this.getObjectPermission(nVal);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 编辑图标样式
.editIconImg {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  height: 12px;
}
// 表头锁定按钮
.lockedImg {
  margin-top: 4px;
  width: 11px;
}
.disableGray {
  color: #bbb;
}
.content-main {
  height: 100%;
  position: relative;
  clear: both;
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 100;
}

.action-btn {
  color: #006dcc !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.table-head-tool {
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
    // width: calc(100% - 40px);
  }

  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }

  .dragBox {
    width: 49%;
  }

  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;

    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;

      i {
        position: absolute;
        z-index: 100;
      }

      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }

  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }

  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }

  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;

    &:hover {
      background: #fafaf9;
    }
  }

  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 0;
  width: calc(100% - 20px);
}

::v-deep .el-progress-bar__outer {
  margin-right: 10px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-table-column--selection .cell {
  text-align: center;
}

::v-deep .el-table__fixed-body-wrapper {
  // top: 48px !important;
}

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}

.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}

// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}

::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}

::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

.block {
  background: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  // justify-content: center;
  padding-left: 20px;
  z-index: 999;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td .cell {
  padding-right: 20px;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}

.admin_s {
  margin: 4px 0 0 0;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  // padding-right: 20px;
}

#table-panel {
  height: calc(100% - 80px);
  width: 100%;
}
// #table-panel .content-main .el-table {
//   tr th {
//     background: #006dcc;
//   }
// }

::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
}
::v-deep .is-checked {
  .el-checkbox__input {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .el-checkbox {
  .is-indeterminate {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
